import RestApi, {
    commonServiceBaseUrl,
    agriMarketingServiceBaseUrl
} from '../config/api_config'

export default {
  computed: {
  },
  watch: {
  },
  created () {
    this.setOrgAndOrgComponentList()
    this.loadDropdownCommonConfig()
    // const hasDropdownLoadedAgriService = this.$store.state.agriObj.hasDropdownLoaded
    // if (!hasDropdownLoadedAgriService || window.performance) {
    //   this.loadDropdownCommonConfig()
    // }
  },
  methods: {
    setOrgAndOrgComponentList () {
        RestApi.getData(commonServiceBaseUrl, 'common/org-and-org-component-list').then(response => {
          if (response.success === true) {
            const tmpData = response.data.orgList.filter(item => {
                if (item.status !== 1) {
                    const tmp = this.$i18n.locale === 'en' ? { text: item.text_en } : { text: item.text_bn }
                    return Object.assign({}, item, tmp)
                }
            })
            this.mixinSetOrganizationProfileList(5, response.data.orgComponentList, tmpData)
          }
        })
    },
    mixinSetOrganizationProfileList (mixinComponentId, orgComponentList, tmpData) {
        const orgComponents = orgComponentList.filter(item => item.component_id === mixinComponentId)
        const orgCompList = orgComponents.map(item => {
            const orgObj = tmpData.find(org => org.value === item.org_id)
            return this.$i18n.locale === 'en' ? Object.assign({}, orgObj, { text: orgObj.text_en }) : Object.assign({}, orgObj, { text: orgObj.text_bn })
        })
        this.$store.commit('Portal/mutateExternalagriDropdown', {
            hasDropdownLoaded: true,
            organizationProfileList: orgCompList
        })
    },
    loadDropdownCommonConfig () {
      RestApi.getData(agriMarketingServiceBaseUrl, 'common-data-dam', null).then(response => {
          if (response.success) {
              this.$store.commit('Portal/mutateExternalagriDropdown', {
                  hasDropdownLoaded: true,
                  divisionList: response.data.divisionList,
                  districtList: response.data.districtList,
                  upazilaList: response.data.upazilaList
              })
              this.$store.dispatch('Portal/localizeExternalagriDropdown', { value: this.$i18n.locale })
          }
      })
      RestApi.getData(agriMarketingServiceBaseUrl, 'common-dropdowns', null).then(response => {
        if (response.success) {
            this.$store.commit('Portal/mutateExternalagriDropdown', {
                hasDropdownLoaded: true,
                communicationLinkageList: response.data.communicationLinkageList,
                designationOfProductList: response.data.designationOfProductList,
                infrastructureList: response.data.infrastructureList,
                vehicleList: response.data.vehicleList,
                marketList: response.data.marketList,
                commodityTypeList: response.data.commodityTypeList,
                commodityGroupList: response.data.commodityGroupList,
                commoditySubGroupList: response.data.commoditySubGroupList,
                commodityNameList: response.data.commodityNameList,
                measurementUnitList: response.data.measurementUnitList
            })
            this.$store.dispatch('Portal/localizeExternalagriDropdown', { value: this.$i18n.locale })
        }
      })
    }
  }
}
