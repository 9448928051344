<template>
  <div class="sidebar_wrapper" style="box-shadow: none">
    <div class="important_link_wrapper">
        <div class="service_info_wrapper" id="__BVID__1646__BV_tab_container_">
          <div role="tabpanel" aria-hidden="true">
            <!-- <a style="color: #ffff;background: var(--brand-secondary-color);border-left-color: var(--brand-color);" class="service_link">
              {{ $t('damReport.market_directory') }}
            </a> -->
            <router-link active-class="active" to="market-daily-price-report" :class="'service_link'">
              {{ $t('damReport.dailyPriceListReport') }}
            </router-link>
            <router-link active-class="active" to="product-wise-market-price-report" class="service_link">
             {{ $t('damReport.product_wise_market_price_report') }}
            </router-link>
            <router-link active-class="active" to="weekly-market-wise-price-report" class="service_link">
             {{ $t('damReport.weeklyMarketWisePriceListReport') }}
            </router-link>
            <router-link active-class="active" to="yearly-average-market-price-report" class="service_link">
             {{ $t('damReport.yearly_average_price_list_report') }}
            </router-link>
            <router-link active-class="active" to="mothly-average-market-price-report" class="service_link">
             {{ $t('damReport.monthly_average_price_list_report') }}
            </router-link>
            <router-link active-class="active" to="weekly-average-price-report" class="service_link">
             {{ $t('damReport.weekly_average_price_report') }}
            </router-link>
            <router-link active-class="active" to="division-wise-average-market-price-report" class="service_link">
             {{ $t('damReport.division_wise_average_market_price_report') }}
            </router-link>
            <router-link active-class="active" to="grower-avg-price-report" class="service_link">
             {{ $t('damReport.grow_avg_price_report_dam') }}
            </router-link>
            <router-link active-class="active" to="comparative-market-price" class="service_link">
             {{ $t('damReport.comparative_market_price_statement') }}
            </router-link>
            <router-link active-class="active" to="price-graphical-report" class="service_link">
             {{ $t('damReport.graphical_report') }}
            </router-link>
            <router-link active-class="active" to="market-directory-report" :class="'service_link'">
              {{ $t('damReport.market_directory_report') }}
            </router-link>
          </div>
      </div>
    </div>
  </div>
</template>
<style>
.main_wrapper .service_info_wrapper .service_link {
  font-size: 13px;
  padding: 4px;
}
a.router-link-exact-active.active.service_link {
  color: var(--iq-white) !important;;
  background: var(--brand-secondary-color) !important;
  border-left-color: var(--brand-color) !important;
}
/*fixed hignt in all list */
.b-table-sticky-header {
    overflow-y: auto;
    max-height: 965px;
}
/*Agrimerketing heading color for all list*/
.steaky-header thead.thead-primary {
    background: #609513!important;
    color: var(--iq-white);
    background: var(--iq-primary)!important;
    background: -webkit-gradient(left top,right top,color-stop(0,var(--iq-primary)),color-stop(100%,var(--iq-primary-light)))!important;
    background: linear-gradient(
    90deg,var(--iq-primary) 0,var(--iq-primary-light))!important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#827af3",endColorstr="#b47af3",GradientType=1)!important;
}
</style>
<script>
export default {
  created () {
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
